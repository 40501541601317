<template>
  <div class="designer-concern-pannel designer-likes-pannel">
    <div class="common-title">点赞我的</div>
    <div
      class="content"
      v-loading="pageLoading"
      element-loading-background="rgba(0, 0, 0, 0.3)"
    >
      <div class="waterfall-wrapper" ref="dwaterfallContainer">
        <HWaterfall
          ref="dwaterfallWrapper"
          :col="maxCols"
          :width="210"
          :gutterWidth="15"
          :data="waterfallList"
          :height="'100%'"
          @loadmore="getData"
          :lazyDistance="0"
          :loadDistance="100"
          @finish="handleFinish"
        >
          <template>
            <div
              class="cell-item hover-item"
              :class="{
                active: '' == item.id,
              }"
              v-for="(item, index) in waterfallList"
              :key="index"
              @mouseenter="getPointUser(item)"
            >
              <!-- <el-image
                  class="img"
                  :src="item.obsThumbUrl || item.obsUrl"
                  fit="contain"
                >
                  <div slot="error" class="img-error">
                    <i
                      class="iconfont icon-zanwutupian"
                      style="font-size: 100px"
                    ></i>
                  </div>
                </el-image> -->
              <img
                v-if="item.obsThumbUrl"
                :src="item.obsThumbUrl"
                alt=""
                class="img"
                fit="contain"
                data-key="mainImg"
                :data-width="item.width"
                :data-height="item.height"
              />
              <img
                v-else-if="item.type==1"
                src="@/assets/images/coverdefault.png"
                alt=""
                class="img"
                fit="contain"
                data-key="mainImg"
                :data-width="item.width-4"
              />
              <img
                v-else-if="item.type==0"
                src="@/assets/images/image_cover_default.png"
                alt=""
                class="img"
                fit="contain"
                data-key="mainImg"
                :data-width="item.width-4"
              />
              <div class="img-error" v-else>
                <i
                  class="iconfont icon-zanwutupian"
                  style="font-size: 100px"
                ></i>
              </div>
              <div class="operation-container">
                <div class="opt-btns">
                  <span class="opt-icon">
                    <!-- <span class="icon-wrapper">
                      <i class="iconfont icon-huiyuanvip"></i>
                    </span> -->
                    <!-- <span
                      class="user-wrapper"
                      @click.stop="
                        $router.push('/community/designer/' + item.userId)
                      "
                      >{{ item.nickname }}</span
                    > -->
                    <el-popover
                      placement="top-start"
                      title="点赞用户"
                      width="200"
                      trigger="hover"
                    >
                      <ul class="point-user-wrapper">
                        <li v-for="item in pointUserList" :key="item.id">
                          {{ item.nickname }}
                          <span>,</span>
                        </li>
                      </ul>
                      <span
                        class="user-wrapper ellipsis"
                        slot="reference"
                        style="text-decoration: none"
                      >
                        <!-- <span v-for="item in [...pointUserList].splice(0,2)" :key="item.id">{{
                          item.nickname
                        }}</span> -->
                        <span>{{
                          [...pointUserList]
                            .splice(0, 2)
                            .map((point) => point.nickname)
                            .join()
                        }}</span>
                        <span v-if="pointUserList.length > 2">...</span>
                      </span>
                    </el-popover>

                    <span class="icon-wrapper" @click.stop="shareWork(item)">
                      <i class="iconfont icon-fenxiang1"></i>
                    </span>
                  </span>
                </div>
                <span class="more-icon">
                  <span class="icon-wrapper" title="暂无更多">
                    <i class="iconfont icon-gengduo1"></i>
                  </span>
                </span>
              </div>
              <div class="name-wrapper">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.name"
                  placement="top-start"
                >
                  <span class="opt-name">{{ item.name }}</span>
                </el-tooltip>
              </div>
              <!-- <div class="follow-info" @click.stop="handleCancelPoint(item)">
                <div class="icon-wrapper">
                  <i class="iconfont icon-aixinshixin"></i>
                </div>
              </div> -->
              <!-- 模型标记 -->
              <div class="model-flag" v-if="item.type == 1">
                <img src="../../../assets/images/icon/icon_models.png" alt="" />
              </div>
            </div>
          </template>
        </HWaterfall>
      </div>

      <Empty v-if="isEmpty" description="没有找到相关作品" />
    </div>
    <!-- 分享文件弹框 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="dialogShareVisible"
      :show-close="false"
      custom-class="info-dialog"
      width="40%"
    >
      <div class="dialog-title" slot="title">共享作品</div>
      <div class="dialog-content">
        <el-form :model="shareForm" ref="shareForm" :rules="shareRules">
          <el-form-item label="已选作品" label-width="120px" prop="works">
            <el-input
              disabled
              v-model="shareForm.works"
              placeholder="请输入"
              type="textarea"
            ></el-input>
          </el-form-item>
          <el-form-item label="共享给" :label-width="'120px'" prop="names">
            <el-select
              v-model="shareForm.names"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="querySearchAsync"
              :loading="shareLoading"
            >
              <el-option
                v-for="item in userOptions"
                :key="item.id"
                :label="item.nickname + '(' + item.username + ')'"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="授权" :label-width="'120px'">
            <el-checkbox v-model="shareForm.allow"
              >是否可以下载原图</el-checkbox
            >
          </el-form-item>
        </el-form>
        <div class="footer-box">
          <el-button
            type="primary"
            @click="handleSubmitShareInfo"
            class="confirm-btn"
            >确定</el-button
          >
          <el-button @click="handleCancelShare" class="cancel-btn"
            >取消</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      waterfallList: [],
      pageParams: {
        pageSize: 50,
        pageNum: 0,
        total: 0,
        currentPage: 0,
      },
      pageLoading: false,
      isEmpty: false,
      imgDataInfo: {},
      maxCols: 5,
      shareId: "",
      dialogShareVisible: false,
      userOptions: [], // 共享人列表
      shareLoading: false,
      shareForm: {
        works: "",
        names: [],
        allow: false,
      }, // 共享表单
      shareRules: {
        works: [
          { required: true, message: "请输入共享的作品名称", trigger: "blur" },
        ],
        names: [
          { required: true, message: "请选择共享的人员", trigger: "change" },
        ],
      },
      pointUserList: [], // 给作品点赞的人
    };
  },
  created() {
    this.getData(true);
  },
  mounted() {
    let self = this;
    self.caculateCol();
    window.onresize = function () {
      self.caculateCol();
    };
  },
  methods: {
    caculateCol() {
      let width = this.$refs.dwaterfallContainer.clientWidth - 100;
      this.maxCols = parseInt(width / 225);
    },
    // 瀑布流元素渲染完成
    handleFinish() {
      console.log("----渲染完成5----");
      // this.isLoad = false;
      this.pageLoading = false;
      // this.loadingInstance.close();
    },
    getData(flag) {
      // console.log("loadmore");
      if (flag) {
        this.waterfallList = [];
        this.pageParams = {
          pageSize: 50,
          pageNum: 0,
          total: 0,
          currentPage: 0,
        };
      }
      if (
        this.pageParams.currentPage * this.pageParams.pageSize >
        this.pageParams.total
      ) {
        // this.$refs.waterfall.waterfallOver();
        return;
      }
      this.pageLoading = true;
      this.pageParams.pageNum++;
      let params = Object.assign({}, this.pageParams);
      this.$http
        .get("/resource-info/getPointToMe", { params })
        .then((res) => {
          // console.log(res);
          if (res.code == this.SUCCESS_CODE) {
            let data = res.data;
            this.pageParams.total = data.total;
            this.totalImage = data.total;
            this.pageParams.currentPage = data.current;
            if (data.records.length) {
              this.waterfallList = this.waterfallList.concat(data.records);
            }
            this.isEmpty = this.waterfallList.length ? false : true;
            if (this.isEmpty) {
              this.pageLoading = false;
            }
          } else {
            this.pageLoading = false;
          }
        })
        .catch((err) => {
          this.waterfallList = [];
          this.isEmpty = true;
          this.pageLoading = false;
        });
    },
    // 取消点赞
    handleCancelPoint(data) {
      let params = {
        id: data.id,
        status: 0,
      };
      this.$http
        .post("/point/modify", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("取消成功！");
            this.$parent.getUserScore();
            this.getData(true);
          } else {
            this.$message.error("取消失败！");
          }
        })
        .catch((err) => {
          this.$message.error("取消失败！");
        });
    },
    // 远程搜索共享人
    querySearchAsync(queryString) {
      // console.log(queryString);
      this.shareLoading = true;
      let params = { search: queryString };
      this.$http
        .get("/sys-user/searchListPage", { params })
        .then((res) => {
          this.shareLoading = false;
          if (res.code == this.SUCCESS_CODE) {
            this.userOptions = res.data;
          }
        })
        .catch((err) => {
          this.shareLoading = false;
        });
    },
    // 分享图片
    shareWork(data) {
      this.dialogShareVisible = true;
      this.shareForm.works = data.name;
      this.shareId = data.id;
    },
    // 共享文件
    shareImage() {
      let rIds = this.shareId;
      let uIds = this.shareForm.names.join();
      let params = {
        rIds,
        uIds,
        allow: this.shareForm.allow,
      };
      this.$http
        .post("/share-info/share", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$refs.shareForm.resetFields(); // 重置表单
            this.dialogShareVisible = false; // 关闭弹框
            this.$message.success("共享成功！");
          }
        })
        .catch((err) => {
          this.$message.error("共享失败！");
        });
    },
    // 上传共享信息
    handleSubmitShareInfo() {
      this.$refs.shareForm.validate((valid) => {
        if (valid) {
          this.shareImage();
        } else {
          return false;
        }
      });
    },
    // 取消共享(表单cancel)
    handleCancelShare() {
      this.$refs.shareForm.resetFields(); // 重置表单
      this.dialogShareVisible = false; // 关闭弹框
    },
    // 获取点赞用户
    getPointUser(data) {
      this.pointUserList = [];
      let params = {
        rid: data.id,
      };
      this.$http.get("/sys-user/pointUser", { params }).then((res) => {
        if (res.code == this.SUCCESS_CODE) {
          console.log(res);
          this.pointUserList = res.data;
        }
      });
    },
  },
};
</script>
